// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import posthog from 'posthog-js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './state/store'
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// Initialize Sentry
Sentry.init({
  dsn: 'https://823c166e257e7486937648808e6d1564@o4506757442633728.ingest.sentry.io/4506757449842688', // Replace this with your actual DSN from Sentry
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 1.0, // You may adjust this value
});

// Initialize PostHog with your API key and config
const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY || 'phc_mcb5ji4Y3aBTJpRM6AMGjJbTz6oyBigvjdpYymBL9UL';
posthog.init(POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });

const root = ReactDOM.createRoot(document.getElementById('root')!);
let persistor = persistStore(store);
root.render(
  /*<React.StrictMode>*/
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();