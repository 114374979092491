import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import { useAppSelector } from "../../../../../hooks/reduxHooks";
import { UserI } from '../../../../../types/user';
import { ChatMsgI } from '../../../../../types/fetch/chatRoom';
import animationData from '../../../../../images/lotties/senso-loader.json';
import { LOTTIE_DEFAULT_OPTIONS } from '../../../../../constants/lottie'
import { useAppDispatch } from "../../../../../hooks/reduxHooks";
import { setCurrentTool } from '../../../../../state/features/app/appSlice';
import { ToolEnum } from '../../../../../enums/app';
import useFetchChatRoomWebSockets from '../../../../../hooks/useFetchChatRoomWebSockets';
import { MsgTypeEnum, ResponseStatusEnum } from '../../../../../enums/fetch/chatRoom';
import { OrganizationI } from '../../../../../types/organization';
import { classNames } from '../../../../../utils/styleHelpers';
import { MultiSourceViewerDataI } from '../../../../../types/ui/multiSourceViewer';
import { useLocation } from 'react-router-dom';
import SkeletonLoader from '../../../../../components/Loaders/SkeletonLoader';
import { trackEvent } from '../../../../../utils/tracking';
import Lottie from 'react-lottie';

const ChatResponse = lazy(() => import('./components/ChatResponse/ChatResponse'));
const ChatInput = lazy(() => import('./components/ChatInput/ChatInput'));
const ChatRoomHeader = lazy(() => import('./components/ChatRoomHeader'));
const MultiSourceViewer = lazy(() => import('../../../../../components/MultiSourceViewer/MultiSourceViewer'));
const UserAvatar = lazy(() => import('../../../../../components/Avatars/UserAvatar'));
const SuggestedQuestions = lazy(() => import('../../../../../components/Chats/SuggestedQuestions'));
const Sessions = lazy(() => import('./components/Sessions'));
const CheckCheck = lazy(() => import('lucide-react').then(module => ({ default: module.CheckCheck })));

const FetchChatRoom = () => {
    const [inputMsg, setInputMsg] = useState<string>('');
    const [scrollIntoEndOfChat, setScrollIntoEndOfChat] = useState<boolean>(false);
    const [multiSourceViewerData, setMultiSourceViewerData] = useState<MultiSourceViewerDataI | undefined>(undefined);
    const [isMultiSourceViewerOpen, setIsMultiSourceViewerOpen] = useState<boolean>(false);
    const [isMetaDataPanelExpanded, setIsMetaDataPanelExpanded] = useState(false);

    const endOfChatRef = useRef<HTMLElement>(null);

    const location = useLocation();
    const dispatch = useAppDispatch();
    const currentUser: UserI = useAppSelector((state) => state.user.currentUser!);
    const selectedOrg: OrganizationI | undefined = useAppSelector((state) => state.organization.selectedOrganization);
    const { connectToHistoricalSession, sessionId, sessionName, setFilterCollectionIds, sendMsg, topQuestions, isPageLoading,
        chatMsgsMap, isLastMsgResponseNotComplete, stopGeneratingAnswer, undoAnyMsgFeedback, updateAnyMsgFeedback } = useFetchChatRoomWebSockets();

    useEffect(() => {
        dispatch(setCurrentTool(ToolEnum.CHAT_ROOM));
    }, []);

    useEffect(() => {
        setInputMsg('');
    }, [sessionId]);

    useEffect(() => {
        if (endOfChatRef.current) {
            endOfChatRef.current.scrollIntoView({ behavior: "auto" });
            setScrollIntoEndOfChat(false);
        }
    }, [scrollIntoEndOfChat, isPageLoading]);

    useEffect(() => {
        if (location.state?.chat && !isPageLoading) {
            sendMsg(location.state.chat.msg, location.state.chat.msgType, location.state.chat.collectionIds);
        }
    }, [location.state?.chat, isPageLoading]);

    const handleInputMsgSubmit = (msgType?: `${MsgTypeEnum}`) => {
        sendMsg(inputMsg, msgType);
        setInputMsg('');
    }

    const handleSuggestedQuestionSelect = (question: string) => {
        // Track the event
        trackEvent('Suggested Question Clicked', {
          question: question
        });
      
        // Call the original sendMsg function
        sendMsg(question);
    };

    const handleStopGeneratingAnswer = () => {
        stopGeneratingAnswer();
    }

    const renderQuestion = (id: number, question: string,) => {
        return (
            <div key={id} className='flex flex-row items-center pb-2 md:pb-5'>
                <div className='flex items-center min-w-[32px] min-h-[32px] !p-0'>
                    <Suspense fallback={<></>}>
                        <UserAvatar currentUser={currentUser} className='!w-8 !h-8' initialsClassName='!text-[14px] md:!text-[12px]' />
                    </Suspense>
                </div>
                <div className='flex'>
                    <span className='flex bg-gray-50 p-2 md:p-3 rounded-lg ml-2 md:ml-3 w-auto text-gray-900'>
                        {question}
                    </span>
                </div>
            </div>
        );
    }

    const SkeletonOfSuggestedQuestion = () => {
        return (
            <>
                <div className='mb-10'></div>
                <div className="flex items-center w-full mb-10">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                    <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                </div>
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-10"></div>
                <div className="flex items-center w-full mb-10">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                    <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                </div>
                <div className="flex items-center w-full mb-10">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                    <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                </div>
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[280px] mb-8"></div>
            </>
        )
    };

    const SkeletonOfChatInput = () => {
        return (
            <>
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[280px] mb-5"></div>
                <div className="h-8  bg-gray-200 dark:bg-gray-700 w-50"></div>
            </>
        )
    }

    return (
        <div className='w-full h-full flex flex-col'>
            <div className='w-full h-full flex'>
                <div className={classNames('hidden flex-col flex-shrink-0 lg:w-[317px] border-r border-b light-scroll-bar overflow-auto', !isMultiSourceViewerOpen ? 'lg:flex' : 'lg:hidden')}>
                    <Suspense fallback={<></>}>
                        <Sessions currentSessionName={sessionName} currentSessionId={sessionId} connectToHistoricalSession={connectToHistoricalSession} />
                    </Suspense>
                </div>
                <>
                    <div className={classNames('h-full flex-col', isMultiSourceViewerOpen && isMetaDataPanelExpanded ? 'w-[calc(100%/2-300px)]' : isMultiSourceViewerOpen && !isMetaDataPanelExpanded ? 'w-1/2' : 'w-full')}>
                        <div className={classNames('w-full h-3/4 px-4 overflow-y-auto flex-grow light-scroll-bar', !isMultiSourceViewerOpen ? 'md:px-28 ' : 'md:px-5')}>
                            {Object.keys(chatMsgsMap).length === 0 ?
                                <>
                                    <div className='w-full text-2xl md:text-4xl font-normal leading-10 pt-4 md:pt-[66px]'>
                                        <span className='w-full flex text-slate-600'>Welcome to </span>
                                        <span className='w-full flex text-primary-color'>{selectedOrg?.name}</span>
                                    </div>
                                    <div className='w-full flex flex-col'>
                                        <div className='w-full'>
                                            <Suspense fallback={<SkeletonLoader skeleton={SkeletonOfSuggestedQuestion} />}>
                                                {!isPageLoading ?
                                                    <SuggestedQuestions title={''} questions={topQuestions} onSelect={handleSuggestedQuestionSelect} />
                                                    : <SkeletonOfSuggestedQuestion />}
                                            </Suspense>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className={classNames('pt-[20px]', !isMultiSourceViewerOpen ? 'md:pt-[50px]' : 'md:pt-[20px]')}>
                                    <Suspense fallback={<></>}>
                                        <ChatRoomHeader isHidden={!isMultiSourceViewerOpen} sessionName={sessionName} handleNaviagetBack={() => setIsMultiSourceViewerOpen(false)} />
                                    </Suspense>

                                    {Object.values(chatMsgsMap).map((chatMsg: ChatMsgI, index) => (
                                        <div key={index} className='justify-items-start'>
                                            {renderQuestion(index, chatMsg.content.question)}
                                            <div className='flex items-center pb-5 pl-9'>
                                                {chatMsg.status === ResponseStatusEnum.RESPONSE_COMPLETE ?
                                                    <div className='pl-2'>
                                                        <Suspense fallback={<></>}>
                                                            <CheckCheck className='text-senso-green' />
                                                        </Suspense>
                                                    </div>
                                                    :
                                                    <div className='items-center'>
                                                        <Suspense fallback={<></>}>
                                                            <Lottie
                                                                options={{ ...LOTTIE_DEFAULT_OPTIONS, animationData: animationData }}
                                                                height={50}
                                                                width={50}
                                                            />
                                                        </Suspense>
                                                    </div>}
                                                <span className='text-sm font-normal text-gray-600 ml-3'>{chatMsg.status}</span>
                                            </div>
                                            <div className='flex items-center justify-items-start'>
                                                <Suspense fallback={<></>}>
                                                    <ChatResponse
                                                        chatMsg={chatMsg}
                                                        sendMsg={sendMsg}
                                                        isLastMsgResponseNotComplete={isLastMsgResponseNotComplete}
                                                        setScrollIntoEndOfChat={setScrollIntoEndOfChat}
                                                        undoAnyMsgFeedback={undoAnyMsgFeedback}
                                                        updateAnyMsgFeedback={updateAnyMsgFeedback}
                                                        setMultiSourceViewerData={setMultiSourceViewerData}
                                                        setIsMultiSourceViewerOpen={setIsMultiSourceViewerOpen} />
                                                </Suspense>
                                            </div>
                                        </div>)
                                    )}
                                    {/* Use to scroll to end of chat, after generating answers */}
                                    <span ref={endOfChatRef}></span>
                                </div>
                            }
                        </div>
                        <div className={classNames('px-4', !isMultiSourceViewerOpen ? 'md:px-28 ' : 'md:px-5')}>
                            <Suspense fallback={<SkeletonLoader skeleton={SkeletonOfChatInput} />}>
                                <ChatInput
                                    inputMsg={inputMsg}
                                    setInputMsg={setInputMsg}
                                    initialFilterCollectionIds={location.state?.chat?.collectionIds}
                                    setFilterCollectionIds={setFilterCollectionIds}
                                    isLastMsgResponseNotComplete={isLastMsgResponseNotComplete}
                                    isMultiSourceViewerOpen={isMultiSourceViewerOpen}
                                    handleInputMsgSubmit={handleInputMsgSubmit}
                                    handleStopGeneratingAnswer={handleStopGeneratingAnswer}
                                />
                            </Suspense>
                        </div>
                    </div>
                    {isMultiSourceViewerOpen &&
                        <div className={classNames('flex h-full', isMetaDataPanelExpanded ? 'w-[calc(100%/2+300px)]' : 'w-1/2')}>
                            <Suspense fallback={<></>}>
                                <MultiSourceViewer
                                    key={multiSourceViewerData!.parentId}
                                    multiSourceViewerData={multiSourceViewerData!}
                                    metadataPanelConfig={{
                                        isMetaDataPanelExpanded,
                                        setIsMetaDataPanelExpanded
                                    }}
                                    handleViewerClose={() => setIsMultiSourceViewerOpen(false)}
                                />
                            </Suspense>
                        </div>
                    }
                </>
            </div>
        </div>
    )
}

export default FetchChatRoom;
