import React, { useMemo, useState, lazy, Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { classNames } from '../../../utils/styleHelpers';
import useLayout from '../../../hooks/useLayout';

const Header = lazy(() => import('../Header/Header'));
const MobileSideBar = lazy(() => import('../MobileSideBar/MobileSideBar'));
const SideBar = lazy(() => import('../SideBar/SideBar'));

const MainLayout = () => {

    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState<boolean>(false);

    const location = useLocation();
    const { hideDefaultHeader } = useLayout();

    const showSideBar: boolean = useMemo(() => {
        return !location.pathname.includes('/knowledge/content'); //To hide sidebar from content create, edit pages
    }, [location]);

    return (
        <>
            <Suspense fallback={<></>}>
                <MobileSideBar isMobileSidebarOpen={isMobileSidebarOpen} setIsMobileSidebarOpen={setIsMobileSidebarOpen} />
            </Suspense>
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex">
                {showSideBar &&
                    <Suspense fallback={<></>}>
                        <SideBar />
                    </Suspense>
                }
            </div>
            <div className={classNames('h-screen flex flex-col', showSideBar && 'lg:pl-[70px] ')}>
                {!hideDefaultHeader &&
                    <Suspense fallback={<></>}>
                        <Header setIsMobileSidebarOpen={setIsMobileSidebarOpen} />
                    </Suspense>
                }
                <Outlet />
            </div>
        </>
    )
}

export default MainLayout;
