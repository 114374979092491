
import { useAppSelector } from "./reduxHooks";
import { ModeTypesEnum } from '../enums/app';
import { useLocation } from "react-router-dom";
import { useCallback } from "react";

const useRoute = () => {

    const location = useLocation();
    const { currentUser, isInOrgMode } = useAppSelector((state) => state.user);
    const currentOrgId = currentUser?.current_org.org_id;
    const currentModeId: string = isInOrgMode ? ModeTypesEnum.ORG_LEVEL.toString() : ModeTypesEnum.NERWORK_LEVEL.toString();

    const getCurrentLocationRoute = useCallback(() => {
        return `${location.pathname}${location.search}`;
    }, [location.pathname, location.search]);

    const getRoute = (route: string, pathParamsMap?: Record<string, string>) => {
        let url = route;
        url = url.replace(':organization_id', currentOrgId!).replace(':mode_id', currentModeId);

        if (pathParamsMap) {
            Object.keys(pathParamsMap).map(nameOfParam =>
                url = url.replace(`:${nameOfParam}`, pathParamsMap[nameOfParam])
            )
        }
        return url;
    }

    return { getRoute, getCurrentLocationRoute }
}

export default useRoute;