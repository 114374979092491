import React from 'react';

interface SkeletonLoaderI {
    skeleton: React.ElementType
}

const SkeletonLoader = (props: SkeletonLoaderI) => {
    const { skeleton: Skeleton } = props;
    return (
        <div role="status" className="animate-pulse">
            <Skeleton />
        </div>
    )
}
export default SkeletonLoader;
