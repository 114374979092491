export enum CollectionVisibilityEnum {
    PRIVATE = 0,
    PUBLIC = 1
};

export enum CollectionOverViewMetricsCardTypeEnum {
    WEEKLY_METRICS = 'weekly_metrics',
    PERSONAS = 'personas',
    EVENTS = 'events',
    TOP_PEOPLE = 'top_people',
    NEGATIVE_QUERIES = "negative_queries"
}

export enum KeyMetricTrendEnum {
    UP = 'up',
    DOWN = 'down'
}

export enum KeyMetricUnitEnum {
    PERCENTAGE = "percent",
    MINUTES = "minutes"
}

export enum OverviewTabNameEnum {
    OVERVIEW = 'Overview',
    CRM = 'CRM'
};

export enum CollectionPageModeEnum {
    OVERVIEW = "overview",
    CRM = "crm",
    CONTENT = "content"
}

export enum CollectionThreeDotsMenuItemsEnum {
    ADD_NEW_CONTENT = 'Add New Content',
    SHARE_COLLECTION = 'Share Collection',
    COPY_URL = 'Copy URL',
    DELETE = 'Delete'
}