import { PUBLIC_ROUTES, PUBLIC_ROUTES_WITH_ONE_PATH_PARAM_AT_END } from '../constants/app';
import { ROUTE_POSTFIX, ROUTE_PREFIX, ROUTE_FETCH_ROOT } from '../constants/routes';

export const getRouteAfterRemovingPathParamAtEnd = (route: string) => {
    return route.substring(0, route.lastIndexOf('/') + 1)
}

export const isPublicRouteWithOnePathParamAtEnd = (route: string): boolean => {
    return !!PUBLIC_ROUTES_WITH_ONE_PATH_PARAM_AT_END.find((publicRoute) => getRouteAfterRemovingPathParamAtEnd(publicRoute) === getRouteAfterRemovingPathParamAtEnd(route));
}

export const getRouteAfterRemovingPostfix = (route: string, postFix: string = ROUTE_POSTFIX): string => {
    return route.replace(postFix, "")
}

export const getRouteAfterRemovingPrefix = (route: string, prefix: string = ROUTE_PREFIX): string => {
    return route.replace(prefix, "")
}

export const getRouteAfterRemovingPrefixAndPostfix = (route: string, prefix: string = ROUTE_PREFIX): string => {
    return getRouteAfterRemovingPostfix(getRouteAfterRemovingPrefix(route, prefix));
}

export const getRouteAfterRemovingQueryParams = (route: string): string => {
    return route.split('?')[0];
} 

export const isPublicRoute = (route: string): boolean => {
    return PUBLIC_ROUTES.includes(route) || isPublicRouteWithOnePathParamAtEnd(route)
}