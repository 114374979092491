import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CollectionI } from '../../../types/fetch/collections';

interface FetchCollectionsStateI {
    selectedCollection?: CollectionI,
    selectedContentId?: string,
    isCollectionsSidebarIconBtnClicked?: boolean
};

const initialState: FetchCollectionsStateI = {};

export const fetchCollectionsSlice = createSlice({
    name: 'fetch-collections',
    initialState,
    reducers: {
        setSelectedCollection: (state, action: PayloadAction<CollectionI | undefined>) => {
            state.selectedCollection = action.payload;
        },
        setSelectedContentId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedContentId = action.payload;
        },
        setIsCollectionsSidebarIconBtnClicked: (state, action: PayloadAction<boolean | undefined>) => {
            state.isCollectionsSidebarIconBtnClicked = action.payload;
        },
        clearFetchCollectionsState: (state) => {
            state.selectedCollection = undefined;
            state.selectedContentId = undefined;
            state.isCollectionsSidebarIconBtnClicked = undefined;
        }
    },
})

export const { setSelectedCollection, setSelectedContentId, setIsCollectionsSidebarIconBtnClicked, clearFetchCollectionsState } = fetchCollectionsSlice.actions;
export default fetchCollectionsSlice.reducer;