import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ProfileStateI {
    isUploadModalOpen: boolean,
    isContentMenuOpen: boolean,
};

const initialState: ProfileStateI = {
    isUploadModalOpen: false,
    isContentMenuOpen: false,
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setIsUploadModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUploadModalOpen = action.payload;
        },
        setIsContentMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.isContentMenuOpen = action.payload;
        },
        clearProfileState: (state) => {
            state.isUploadModalOpen = false;
            state.isContentMenuOpen = false;
        }
    },
})

export const { setIsUploadModalOpen, setIsContentMenuOpen, clearProfileState } = profileSlice.actions;
export default profileSlice.reducer;