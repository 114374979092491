import { useEffect, useMemo, lazy, Suspense, useState } from 'react';
import Modal from 'react-modal';
import styles from './AgentFetch.module.css';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setCurrentModule } from '../../state/features/app/appSlice';
import { ModuleEnum } from '../../enums/app';
import { setIsNewSession } from '../../state/features/fetchChatRoom/fetchChatRoomSlice';
import { ROUTE_FETCH_CHAT_ROOM, ROUTE_FETCH_COLLECTION_CONTENT, ROUTE_PROFILE, ROUTE_FETCH_COLLECTION_CONTENT_REFRESH, ROUTE_FETCH_COLLECTION_OVERVIEW, ROUTE_FETCH_INTERACTIVE_METRICS_ANALYTICS, ROUTE_FETCH_ROOT, ROUTE_FETCH_COLLECTION_CRM } from '../../constants/routes';
import useRoute from '../../hooks/useRoute';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import { API_URL } from '../../config';
import { setIsFilterModalOpen } from '../../state/features/agentEcho/agentEchoSlice';
import FetchChatRoom from './components/FetchChatRoom/FetchChatRoom/FetchChatRoom';
import { CollectionPageModeEnum } from '../../enums/fetch/collections';
import { getRouteAfterRemovingPrefixAndPostfix } from '../../utils/routeHelpers';

const FetchEvents = lazy(() => import('./components/FetchEvents/FetchEvents'));
const FetchEventCategory = lazy(() => import('./components/FetchEvents/FetchEventCategory/FetchEventCategory'));
const FetchEventSubCategory = lazy(() => import('./components/FetchEvents/FetchEventCategory/FetchEventSubCategory/FetchEventSubCategory'));
const FetchAnalytics = lazy(() => import('./components/FetchMetrics/FetchAnalytics/FetchAnalytics'));
const FetchQueries = lazy(() => import('./components/FetchMetrics/FetchQueries/FetchQueries'));
const FetchKnowledge = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchKnowledgeDocuments'));
const FetchContentCreate = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchContent/FetchContentCreate/FetchContentCreate'));
const FetchContentEdit = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchContent/FetchContentEdit/FetchContentEdit'));
const FetchTags = lazy(() => import('./components/FetchKnowledge/FetchTags/FetchTags'));
const FetchCollections = lazy(() => import('./components/FetchCollections/FetchCollectionsOld'));
const FetchCollection = lazy(() => import('./components/FetchCollections/FetchCollection/FetchCollection'));
const AgentProfile = lazy(() => import('./components/AgentProfile/AgentProfile'));
const FetchSave = lazy(() => import('./components/FetchSave/FetchSave'));
const FetchFilters = lazy(() => import('./components/FetchFilters/FetchFilters'));
const FetchKnowledgeInteractions = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchKnowledgeInteractions'));
const FetchInteractiveMetrics = lazy(() => import('./components/FetchInteractiveMetrics/FetchInteractiveMetrics'));

const AgentFetch = () => {
    const [selectedFilters, setSelectedFilters] = useState({
        sentiments: [],
        emotions: [],
        purposes: [],
        reps: [],
        startDate: getStartOfWeek(),
        endDate: getEndOfWeek()
    });

    const [filters, setFilters] = useState({
        sentiments: [],
        emotions: [],
        purposes: [],
        reps: []
    });
    const location = useLocation();
    const { getRoute } = useRoute();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedOrgId = useAppSelector((state) => state.organization.selectedOrganization?.org_id);
    const { isSaveModalOpen, isFilterModalOpen } = useAppSelector((state) => state.agentEcho);

    useEffect(() => {
        setSelectedFilters({
            sentiments: [],
            emotions: [],
            purposes: [],
            reps: [],
            startDate: getStartOfWeek(),
            endDate: getEndOfWeek()
        });
        setFilters({
            sentiments: [],
            emotions: [],
            purposes: [],
            reps: []
        });
        fetchFilters();
    }, [selectedOrgId]);

    useEffect(() => {
        dispatch(setCurrentModule(ModuleEnum.FETCH));
    }, [dispatch]);

    const isNewChatRoomSession = useAppSelector((state) => state.fetchChatRoom.isNewSession);
    const currentUser = useAppSelector((state) => state.user.currentUser);
    const isInOrgMode = useAppSelector((state) => state.user.isInOrgMode);
    const firstCollection = useAppSelector((state) => state.user.firstCollection);

    useEffect(() => {
        if (isNewChatRoomSession) {
            dispatch(setIsNewSession(false));
            navigate(getRoute(ROUTE_FETCH_CHAT_ROOM));
        }
    }, [isNewChatRoomSession]);

    async function fetchFilters() {
        try {
            const headers = await getAmplifySessionHeaders();
            const response = await fetch(`${API_URL}/interactions/filters/`, {
                method: 'POST', // Specify the method
                headers: {
                    ...headers,
                    'Content-Type': 'application/json' // Include the content type header
                }
            });
            if (response.ok) {
                const data = await response.json();
                setFilters(data);

                // Set all filters as selected by default
                const updatedSelectedFilters = { ...selectedFilters };
                Object.keys(data).forEach(category => {
                    updatedSelectedFilters[category] = data[category];
                });
                setSelectedFilters(updatedSelectedFilters);
            } else {
                console.error("Error fetching filter data:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching filter data:", error);
        }
    }

    function getStartOfWeek() {
        const date = new Date();
        date.setMonth(date.getMonth() - 3); // Set the date to three months ago
        return date.toISOString().split('T')[0];
    }

    function getEndOfWeek() {
        const date = new Date();
        date.setDate(date.getDate() + 1); // Set the date to tomorrow
        return date.toISOString().split('T')[0];
    }

    const hasCollectionViewPermissions = useMemo(() => {
        return currentUser?.ui_settings?.view_collections === true;
    }, [currentUser]);

    if (!currentUser) {
        return null;
    }

    return (
        <>
            <Modal isOpen={isFilterModalOpen} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
                <Suspense fallback={<></>}>
                    <FetchFilters
                        onClose={() => dispatch(setIsFilterModalOpen(false))}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                    />
                </Suspense>
            </Modal>
            <Modal isOpen={isSaveModalOpen} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
                <Suspense fallback={<></>}>
                    <FetchSave selectedFilters={selectedFilters} />
                </Suspense>
            </Modal>
            <div className={styles.content}>
                <Routes>
                    {/*Import directly the Chat room page to avoid high loading time for the landing page of the application*/}
                    <Route path="/chat-room" element={
                        isInOrgMode ?
                            <FetchChatRoom key={location.key} />
                            : <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/events" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchEvents key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/events/:category_id" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchEventCategory />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/events/:category_id/sub/:sub_category_id" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchEventSubCategory />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/metrics/analytics" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchAnalytics key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/metrics/queries" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchQueries key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/documents" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchKnowledge key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/interactions" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchKnowledgeInteractions key={location.key} selectedFilters={selectedFilters} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/:document_id/edit" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentEdit key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/create" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentCreate key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/tags" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchTags key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/collections" element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollections key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path="/rep/:rep_name" element={
                        <Suspense fallback={<></>}>
                            <AgentProfile />
                        </Suspense>
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_INTERACTIVE_METRICS_ANALYTICS, ROUTE_FETCH_ROOT)}`} element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchInteractiveMetrics selectedFilters={selectedFilters} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/metrics/queries" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchQueries key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchKnowledge key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/:document_id/edit" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentEdit key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/create" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentCreate key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/tags" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchTags key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/collections" element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollections key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                     <Route path="/collections/:collection_id/content/:content_id" element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollection pageMode={CollectionPageModeEnum.CONTENT}/>
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_COLLECTION_OVERVIEW, ROUTE_FETCH_ROOT )}`} element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollection pageMode={CollectionPageModeEnum.OVERVIEW}/>
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                       <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_COLLECTION_CRM, ROUTE_FETCH_ROOT)}`} element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollection pageMode={CollectionPageModeEnum.CRM}/>
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path="/rep/:rep_uuid" element={
                        <Suspense fallback={<></>}>
                            <AgentProfile />
                        </Suspense>
                    } />

                    <Route path="/collection-content/refresh" element={<Navigate to={getRoute(ROUTE_FETCH_COLLECTION_CONTENT, { collection_id: firstCollection?.collection_id ?? 'undefined', content_id: firstCollection?.first_document_id ?? 'undefined' })} replace />} />
                    <Route path="*" element={<Navigate replace to={isInOrgMode ? getRoute(ROUTE_FETCH_CHAT_ROOM) : getRoute(ROUTE_PROFILE)} key={location.key} />} />
                    <Route path={getRoute(ROUTE_FETCH_COLLECTION_CONTENT_REFRESH)}
                        element={<Navigate to={getRoute(ROUTE_FETCH_COLLECTION_CONTENT,
                            {
                                collection_id: firstCollection?.collection_id ?? 'undefined',
                                content_id: firstCollection?.first_document_id ?? 'undefined'
                            }
                        )}
                            replace />} />
                    <Route path="*" element={<Navigate replace to={getRoute(isInOrgMode ? ROUTE_FETCH_CHAT_ROOM : ROUTE_PROFILE)} key={location.key} />} />
                </Routes>
            </div>
        </>
    );
};

export default AgentFetch;